<template>
    <div>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        :table-filter="true"
        pagination
        items-per-page-select
        :tableFilter='{ placeholder: "Search", label: " "}'
      >
        <template #status="{item}">
          <td>
            <CBadge :color="getBadge(item.status)">{{item.status}}</CBadge>
          </td>
        </template>
        <template #branch="{item}">
          <td>
            {{item.branch.branch_address}}
          </td>
        </template>
        <template #role="{item}">
          <td>
            {{item.role.role_name}}
          </td>
        </template>
        <template #action="{item}">
            <td>
                <CButtonGroup>
                    <div style="position:relative;">
                        <CButton v-if="$ability.can('update', 'mJYCWRv')" size="sm" @click="getValue(item)" color="info"><CIcon name="cil-pencil"/></CButton>
                        <RoleModal v-if="$store.state.permission.permission_settings" elementKey="mJYCWRv" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                    </div>
                    <div style="position:relative;">
                        <CButton v-if="$ability.can('delete', 'VfiPevM')" size="sm" @click="getValueDel(item)" color="danger"><CIcon name="cil-trash"/></CButton>
                        <RoleModal v-if="$store.state.permission.permission_settings" elementKey="VfiPevM" customClass="tableBtn" actionKey="delete" :keyPage="$route.name"/>
                    </div>
                </CButtonGroup>
            </td>
        </template>
      </CDataTable>
    </div>
</template>

<script>
import RoleModal from "../../containers/RoleModal"
export default {
  name: 'Table',
  components: {
    RoleModal
  },
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return ['name', 'branch', 'role', 'action']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  methods: {
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    getValue(data){
      this.$emit('event_child', data, 'edit');
    },
    getValueDel(data){
      this.$emit('event_child', data, 'delete');
    },
  }
}
</script>
