<template>
    <CModal
      :show.sync="editRoleModal"
      :closeOnBackdrop="false"
      :centered="true"
      size="lg"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">UPDATE ROLE</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
        <CCol sm="6">
            <CInput
                label="ROLE NAME"
                v-model="form.role_name"
            />
        </CCol>
        <CCol sm="12">
            <div class="my-2 p-2" style="border:1px solid #E0681E;" v-for="(permission, title) in permissions" :key="title">
                <h5>{{title}}</h5>
                <CInputCheckbox v-for="(items, index) in permission" :key="index" inline :label="items.title" @update:checked="chkChanged" :checked="checkChecked(items)" :ref="'chkbox' + items.id" :value="items"/>
            </div>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="submit" color="success" class="branding-btn">UPDATE</CButton>
        <CButton @click="editRoleModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>
</template>
<script>
import vSelect from 'vue-select'

export default {
    data() {
        return {
            editRoleModal: false,
            form: {
                id: '',
                role_name: '',
                permissions: []
            },
            permissions: []
        }
    },
    components: {
        vSelect
    },
    filters: {
        permissionFilter(data){
            // if(data){
            //     const options = data.reduce((option, item) => {
            //         option.push({label: item.title, value: item.id})
            //         return option
            //     }, [])
            //     return options;
            // }
        }
    },
    props: ['editRoleData'],
    watch: {
        editRoleData(data){
            this.form.id = data.data.id;
            this.form.role_name = data.data.role_name;

            let permission = [];
            data.data.permissions.forEach(item => {
                permission.push(item.permission_id);
            });
            
            this.form.permissions = permission;
            
            this.$store.dispatch('permission/fetchPermission').then(response => {
                const groupByPage = response.reduce((group, permission) => {
                const { page } = permission;
                group[page] = group[page] ?? [];
                group[page].push(permission);
                return group;
                }, {});
                this.permissions = groupByPage;
            });
            console.log(this.permissions);
            this.editRoleModal = true;
        }
    },
    methods: {
        chkChanged(value, obj){
            if(value){
                this.form.permissions.push(obj.target._value.id);
            }else{
                let items = this.form.permissions.filter(item => item != obj.target._value.id);
                this.form.permissions = items;
            }
        },
        checkChecked(value){
            let items = this.form.permissions.find(item => parseInt(item) === value.id);
            return items != null ? true : false
        },
        submit(){
            this.$store.dispatch('option/editRole', this.form).then(response => {
                this.editRoleModal = false;
            });
        }
    }
}
</script>