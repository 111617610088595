<template>
     <CModal
      :show.sync="editModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">EDIT USER</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
        <CCol sm="12">
            <CInput
                label="Name"
                placeholder="NAME"
                v-model="form_edit.name"
            />
            <CInput
                label="Username"
                placeholder="USERNAME"
                v-model="form_edit.username"
            />
            <CInput
                label="Email"
                placeholder="EMAIL"
                v-model="form_edit.email"
            />
            <CInput
                label="Password"
                type="password"
                placeholder="PASSWORD"
                v-model="form_edit.password"
            />
            <CSelect
                label="Role"
                :options="roles | selectData"
                :value.sync="form_edit.role_id"
            />
            <CSelect
                label="Branch"
                :options="$store.state.branch.branches | branchFilter"
                :value.sync='form_edit.branch_id'
            />
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="updateUsers" color="success" class="branding-btn">UPDATE</CButton>
        <CButton @click="editModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>
</template>
<script>
export default {
    data() {
        return {
            editModal: false, 
            form_edit: {
                id: '',
                name: '',
                username: '',
                password: '',
                role_id: '',
                branch_id: ''
            },
        }
    },
    props:['roles', 'updateModal'],
    watch: {
        updateModal(newVal){
            this.form_edit = newVal.data;
            this.editModal = true;
        }
    },
    filters: {
        selectData: function (value) {
            if(value){
                var i;
                var roles = [];
                for (i = 0; i < value.length; i++) {
                    if(value[i].role_name){
                        roles.push({ 
                        value: value[i].id, 
                        label: value[i].role_name
                        }); 
                    }
                
                }
                return roles;
            }
        },
        branchFilter(data){
            if(data){
                const options = data.reduce((option, item) => {
                    option.push({label: item.branch_address, value: item.id})
                    return option
                }, [])
                return options;
            }
        }
    },
    methods: {
        updateUsers(){
            this.$store.dispatch('users/updateUsers',this.form_edit).then(() => {
                this.editModal = false;
            });
        }
    },
    created(){
        this.$store.dispatch('branch/fetchBranch');
    }
}
</script>