<template>
    <CModal
      :show.sync="openModal"
      title="Permission"
      :no-close-on-backdrop="true"
      :centered="true"
      size="lg"
      color="dark"
    >
      <div>
        <CRow>
            <CCol col="4"><strong>Location</strong></CCol>
            <CCol col="2"><strong>View</strong></CCol>
            <CCol col="2"><strong>Create</strong></CCol>
            <CCol col="2"><strong>Update</strong></CCol>
            <CCol col="2"><strong>Delete</strong></CCol>
        </CRow>
        <template v-for="data in parent">
            <CRow :key="data.id">
                <CCol lg="4" >
                    <img height="15" src="/img/brand/right-arrow.svg"/>{{data.route_name}}
                </CCol>
                <CCol lg="2" >
                    <CSwitch
                        :class="chkbox_value('view', data.id, 'parent')"
                        color="warning"
                        :checked="changeBoolean(data.view)"
                        @update:checked="saveChanges"
                        ref="checkbox_view"
                    />
                </CCol>
                <CCol lg="2">
                    <CSwitch
                        :class="chkbox_value('create', data.id, 'parent')"
                        color="warning"
                        :checked="changeBoolean(data.create)"
                        @update:checked="saveChanges"
                        ref="checkbox_view"
                    />
                </CCol>
                <CCol lg="2">
                    <CSwitch
                        :class="chkbox_value('update', data.id, 'parent')"
                        color="warning"
                        :checked="changeBoolean(data.update)"
                        @update:checked="saveChanges"
                        ref="checkbox_view"
                    />
                </CCol>
                <CCol lg="2">
                    <CSwitch
                        :class="chkbox_value('delete', data.id, 'parent')"
                        color="warning"
                        :checked="changeBoolean(data.delete)"
                        @update:checked="saveChanges"
                        ref="checkbox_view"
                    />
                </CCol>
                <template v-for="child in data.permissions_child">
                <CCol lg="4">
                    <img class="ml-2" height="15" src="/img/brand/subdirectory-arrow.svg"/>{{child.route_name}}
                </CCol>
                <CCol lg="2">
                    <CSwitch
                        :class="chkbox_value('view', child.id, 'child')"
                        color="warning"
                        :checked="changeBoolean(child.view)"
                        @update:checked="saveChanges"
                        ref="checkbox_view"
                    />
                </CCol>
                <CCol lg="2">
                    <CSwitch
                        :class="chkbox_value('create', child.id, 'child')"
                        color="warning"
                        :checked="changeBoolean(child.create)"
                        @update:checked="saveChanges"
                        ref="checkbox_view"
                    />
                </CCol>
                <CCol lg="2">
                    <CSwitch
                        :class="chkbox_value('update', child.id, 'child')"
                        color="warning"
                        :checked="changeBoolean(child.update)"
                        @update:checked="saveChanges"
                        ref="checkbox_view"
                    />
                </CCol>
                <CCol lg="2">
                    <CSwitch
                        :class="chkbox_value('delete', child.id, 'child')"
                        color="warning"
                        :checked="changeBoolean(child.delete)"
                        @update:checked="saveChanges"
                        ref="checkbox_view"
                    />
                </CCol>
                <template v-for="item in child.permissions_item">
                    <CCol lg="4">
                        <img class="ml-4" height="15" src="/img/brand/subdirectory-arrow.svg"/>{{item.route_name}}
                    </CCol>
                    <CCol lg="2">
                        <CSwitch
                            :class="chkbox_value('view', item.id, 'item')"
                            color="warning"
                            :checked="changeBoolean(item.view)"
                            @update:checked="saveChanges"
                            ref="checkbox_view"
                        />
                    </CCol>
                    <CCol lg="2">
                        <CSwitch
                            :class="chkbox_value('create', item.id, 'item')"
                            color="warning"
                            :checked="changeBoolean(item.create)"
                            @update:checked="saveChanges"
                            ref="checkbox_view"
                        />
                    </CCol>
                    <CCol lg="2">
                        <CSwitch
                            :class="chkbox_value('update', item.id, 'item')"
                            color="warning"
                            :checked="changeBoolean(item.update)"
                            @update:checked="saveChanges"
                            ref="checkbox_view"
                        />
                    </CCol>
                    <CCol lg="2">
                        <CSwitch
                            :class="chkbox_value('delete', item.id, 'item')"
                            color="warning"
                            :checked="changeBoolean(item.delete)"
                            @update:checked="saveChanges"
                            ref="checkbox_view"
                        />
                    </CCol>
                </template>  
                </template>
            </CRow>

        </template>
      </div>
      <template #footer>
        <CButton @click="openModal = false" color="danger">CANCEL</CButton>
      </template>
    </CModal>
</template>
<script>

export default {
    data(){
        return {
            openModal: false,
            parent: [],
        }
    },
    props: ['permissionData'],
    watch: {
        permissionData(newVal){
            this.openModal = true;
            this.parent = newVal.data.permissions;
        }
    },
    methods: {
        chkbox_value(data, id, parent, role_id){
            return data + ' ' + id + ' ' + parent + ' ' + role_id;
        },
        changeBoolean(data){
            if(data == 1){
                return true;
            }else{
                return false;
            }
        },
        toggleBoolean(data){
            if(data == 1){
                return false;
            }else{
                return true;
            }
        },
        saveChanges(value, event){
            const data = event.path[1].className;
            const split = data.split(' ');
            
            const params = {
                id: split[4],
                location: split[3],
                data: value,
                parent: split[5]
            }
            console.log(params);

            this.$store.dispatch('permission/updatePermission', params).then(() => {
            });
        }
    },
}
</script>