<template>
    <CModal
      :show.sync="myModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">ADD USER</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
        <CCol sm="12">
            <CInput
                label="NAME"
                v-model="form.name"
            />
            <CInput
                label="USERNAME"
                v-model="form.username"
            />
            <CInput
                label="EMAIL"
                v-model="form.email"
            />
            <CInput
                type="password"
                label="PASSWORD"
                v-model="form.password"
            />
            <div class="form-group">
              <label>ROLE</label>
              <CSelect
                  placeholder="Select Role"
                  :options="roles | selectData"
                  :value.sync='form.role_id'
              />
            </div>
            <div class="form-group">
              <label>Branch</label>
              <CSelect
                  placeholder="Select Branch"
                  :options="$store.state.branch.branches | branchFilter"
                  :value.sync='form.branch_id'
              />
            </div>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="submit" color="success" class="branding-btn">ADD</CButton>
        <CButton @click="myModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>
</template>
<script>
export default {
    data(){
        return {
            myModal: false,
            form: {
                name: '',
                username: '',
                password: '',
                role_id: '',
                branch_id: ''
            },
        }
    },
    props: ['roles', 'addModal'],
    watch: {
        addModal(){
            this.myModal = true;
        }
    },
    filters: {
        selectData: function (value) {
            if(value){
                var i;
                var roles = [];
                for (i = 0; i < value.length; i++) {
                    if(value[i].role_name){
                        roles.push({ 
                        value: value[i].id, 
                        label: value[i].role_name
                        }); 
                    }
                
                }
                return roles;
            }
        },
        branchFilter(data){
            if(data){
                const options = data.reduce((option, item) => {
                    option.push({label: item.branch_address, value: item.id})
                    return option
                }, [])
                return options;
            }
        }
    },
    methods: {
        submit(){
            this.$store.dispatch('users/addUser', this.form).then(() => {
                this.myModal = false;
                
                this.form.name = '';
                this.form.username = '';
                this.form.password = '';
                this.form.email = '';
                this.form.role_id = '';
                this.form.branch_id = '';
            });
        }
    },
    created(){
        this.$store.dispatch('branch/fetchBranch');
    }
}
</script>