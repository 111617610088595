<template>
    <CModal
      :show.sync="myModal"
      :closeOnBackdrop="false"
      :centered="true"
      size="lg"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">ADD ROLE</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
        <CCol sm="6">
            <CInput
                label="ROLE NAME"
                v-model="form.role_name"
            />
        </CCol>
        <CCol sm="12">
            <div class="my-2 p-2" style="border:1px solid #E0681E;" v-for="(permission, title) in permissions" :key="title">
                <h5>{{title}}</h5>
                <CInputCheckbox v-for="(items, index) in permission" :key="index" inline :label="items.title" @update:checked="chkChanged" :value="items"/>
            </div>
        </CCol>
        <!-- <CCol sm="12">
            <v-select 
                :options="$store.state.permission.permission | permissionFilter"
                placeholder="Nothing Selected"
                multiple
                v-model="form.permissions"
                :reduce="label => label.value"
            />
        </CCol> -->
      </CRow>
      <template #footer>
        <CButton @click="submit" color="success" class="branding-btn">ADD</CButton>
        <CButton @click="myModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>
</template>
<script>
import vSelect from 'vue-select'

export default {
    data() {
        return {
            myModal: false,
            form: {
                role_name: '',
                permissions: []
            },
            permissions: []
        }
    },
    components: {
        vSelect
    },
    filters: {
        permissionFilter(data){
            // if(data){
            //     const options = data.reduce((option, item) => {
            //         option.push({label: item.title, value: item.id})
            //         return option
            //     }, [])
            //     return options;
            // }
            console.log(data);
        }
    },
    props: ['addRoleModal'],
    watch: {
        addRoleModal(){
            this.$store.dispatch('permission/fetchPermission').then(response => {
                const groupByPage = response.reduce((group, permission) => {
                const { page } = permission;
                group[page] = group[page] ?? [];
                group[page].push(permission);
                return group;
                }, {});
                console.log(groupByPage);
                this.permissions = groupByPage;
            });
            this.myModal = true;
        }
    },
    methods: {
        chkChanged(value, obj){
            if(value){
                this.form.permissions.push(obj.target._value.id);
            }else{
                let items = this.form.permissions.filter(item => item != obj.target._value.id);
                this.form.permissions = items;
            }
        },
        submit(){
            this.$store.dispatch('option/addRole', this.form).then(() => {
                this.myModal = false;
            });
        }
    }
}
</script>
