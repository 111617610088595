<template>
  <div>
    <CRow>
      <CCol lg="12">
          <CCard>
          <CCardHeader style="position:relative;">
            <strong>Users</strong>
            <div class="card-header-actions">
              <CButton v-if="$ability.can('create', 'Uoqy0N3')" @click="addModal = new Date()" style="position:absolute; right:10px; top:8px;" size="sm" class="branding-btn"><CIcon name="cil-plus"/></CButton>
              <RoleModal v-if="$store.state.permission.permission_settings" elementKey="Uoqy0N3" customClass="headerBtn2" actionKey="create" :keyPage="$route.name"/>
            </div>
          </CCardHeader>
          <CCardBody>
            <CTableWrapper
            :items="getShuffledUsersData()"
            striped
            v-on:event_permission_child="eventPermissionChild"
            caption="Users"
            v-on:event_child="eventChild"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="12">
          <CCard>
          <CCardHeader style="position:relative;">
            <strong>Roles & Permission</strong>
            <div class="card-header-actions">
              <CButton v-if="$ability.can('create', 'OdsE9T9')" @click="addRoleModal = new Date()" style="position:absolute; right:10px; top:8px;" size="sm" class="branding-btn"><CIcon name="cil-plus"/></CButton>
              <RoleModal v-if="$store.state.permission.permission_settings" elementKey="OdsE9T9" customClass="headerBtn2" actionKey="create" :keyPage="$route.name"/>
            </div>
          </CCardHeader>
          <CCardBody>
            <RolePermissionTable
            :items="getShuffledRoleData()"
            striped
            v-on:event_permission_child="eventPermissionChild"
            caption="Users"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <add-user-modal
    :addModal="addModal"
    :roles="roles"
    />

    <edit-user-modal
    :updateModal="updateModal"
    :roles="roles"
    />

    <delete-user-modal
    :deleteModal="deleteModal"
    />

    <add-permission-modal 
    :addRoleModal="addRoleModal"
    />

    <edit-permission-modal 
    :editRoleData="editRoleData"
    />

    <delete-permission-modal 
    :deleteRoleModal="deleteRoleModal"
    />

    <permission-modal 
    :permissionData="permissionData"
    />
  </div>
</template>

<script>
import CTableWrapper from './UsersTable.vue'
import RolePermissionTable from './Role_PermissionTable.vue'
import AddUserModal from './AddUserModal.vue'
import EditUserModal from './EditUserModal.vue'
import DeleteUserModal from './DeleteUserModal.vue'
import PermissionModal from './PermissionModal.vue'
import AddPermissionModal from './AddPermission.vue'
import EditPermissionModal from './EditPermissionModal.vue'
import DeletePermissionModal from './DeletePermissionModal.vue'
import { mapGetters, mapActions } from 'vuex';
import RoleModal from "../../containers/RoleModal"

export default {
  name: 'Dashboard',
  components: {
      CTableWrapper,
      RolePermissionTable,
      AddUserModal,
      EditUserModal,
      DeleteUserModal,
      PermissionModal,
      AddPermissionModal,
      EditPermissionModal,
      DeletePermissionModal,
      RoleModal
  },
  data () {
      return {
          addModal: '',
          updateModal: '',
          deleteModal: '',
          permissionData: '',
          addRoleModal: null,
          editRoleData: '',
          deleteRoleModal: ''
      }
  },
  computed: {
    ...mapGetters({ 
        roles: "option/roles",
        user: "auth/user",
        users: "users/users",
    }),
  },
  filters: {
        selectData: function (value) {
            if(value){
                var i;
                var roles = [];
                for (i = 0; i < value.length; i++) {
                    if(value[i].role_name){
                        roles.push({ 
                        value: value[i].id, 
                        label: value[i].role_name
                        }); 
                    }
                   
                }
                return roles;
            }
        },
  },
  methods: {
    ...mapActions({
      fetchRoles: "option/getRoles",
      fetchUsers: "users/fetchUsers",
      addUsers: "users/addUser",
      usersUpdate: "users/updateUsers",
      removeUsers: "users/removeUsers",
    }),
    eventChild: function(data, status) {
        if(status == 'edit'){
          const params = {
            branch: data.branch,
            branch_id: data.branch_id,
            email: data.email,
            id: data.id,
            name: data.name,
            role: data.role,
            role_id: data.role_id,
            username: data.username,
            password: ''
          };
          this.updateModal =  {
            trigger: new Date(),
            data: params
          };
          this.editModal = true;
        }else{
          this.deleteModal =  {
            trigger: new Date(),
            data: data
          };
          this.dangerModal = true;
        }
    },
    eventPermissionChild: function(data, status) {
        console.log('test');
        if(status == 'edit'){
          this.editRoleData =  {
            trigger: new Date(),
            data: data
          };
        } else {
          this.deleteRoleModal =  {
            trigger: new Date(),
            data: data
          };
        }
    },
    submit(){
        this.form.branch_id = this.user.branch.id;
        this.addUsers(this.form).then(() => {
            this.myModal = false;
            
            this.form.name = '';
            this.form.username = '';
            this.form.password = '';
            this.form.email = '';
            this.form.role_id = '';

        });
        
    },
    confirmDelete(id){
      this.dangerModal = false;
      this.removeUsers(id);
    },
    updateUsers() {
      this.usersUpdate(this.form_edit);
      this.editModal = false;
    },
    getShuffledUsersData () {
      return this.users;
    },
    getShuffledRoleData () {
      return this.roles;
    },
  },
  created(){
      this.fetchRoles();
      this.fetchUsers();
  }
}
</script>
<style>
.vs__dropdown-toggle {
    height:auto;
}
</style>