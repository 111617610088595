<template>
    <CModal
      :show.sync="dangerModal"
      title="Delete confirmation"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      Are you sure you want to delete <b>{{form_delete.role_name}}</b>?
      <template #footer>
        <CButton @click="confirmDelete(form_delete.id)" color="success" class="branding-btn">YES</CButton>
        <CButton @click="dangerModal = false" color="danger">CANCEL</CButton>
      </template>
    </CModal>
</template>
<script>
export default {
    data(){
        return {
            form_delete: [],
            dangerModal: false,
        }
    },
    props: ['deleteRoleModal'],
    watch: {
        deleteRoleModal(newVal){
            this.dangerModal = true;
            this.form_delete = newVal.data;
        }
    },
    methods: {
        confirmDelete(id){
            console.log(id);
            this.$store.dispatch('option/removeRoles', id).then(() => {
                this.dangerModal = false;
            });
        },
    }
}
</script>